import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/global/Layout"
import Form from "../components/templates/Form"
import showdown from "showdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/global/Seo"

export default function About({ data }) {
    const frontMatter = data.file.childMarkdownRemark.frontmatter
    const converter = new showdown.Converter();
    return (
        <>
            <Layout>
              <Seo
                title="About Siavosh Ardalan"
                description="Since 1991 Siavosh Ardalan (Broker/Owner) has always served the community with integrity and professionalism"
              />
                <div className="section bg">
                    <div className="container">
                        <div className="md:flex flex-none gap-5">
                            <div className="md:w-1/2 text-center">
                                <GatsbyImage
                                    image={frontMatter.image.childImageSharp.gatsbyImageData}
                                />
                            </div>
                            <div className="md:w-1/2 md-content">
                                <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.file.childMarkdownRemark.rawMarkdownBody) }} />

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export const query = graphql`
{
    file(absolutePath: {regex: "/content/pages/about/"}) {
      childMarkdownRemark {
        frontmatter {
          title
          search_engine_optimization {
            meta_description
            title_tag
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 50)
            }
          }
        }
        rawMarkdownBody
      }
    }
  }
  
`